<template>
    <div class="page after-market-config">
        <div class="cell" v-for="item in items" :key="item.key">
            <div class="label"><span v-if="item.required">*</span>{{item.label}}:</div>
            <div class="value-content">
                <template v-if="item.type === 'switch'">
                    <el-switch v-model="data[item.key]" :active-value="1" :inactive-value="0"></el-switch>
                    <span class="switch-tip">{{data[item.key] ? "已" : "未"}}开启</span>
                </template>
                <el-input v-else v-model="data[item.key]" :maxlength="item.maxLength"></el-input>
            </div>
            <div class="tip" v-if="item.tip">{{item.tip}}</div>
        </div>
        <div class="btn" @click="save">保存</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {},
            items: [
                // {label: "开启售后服务", key: "isStart", type: "switch", tip: "开启后仅支持实物类订单退货退款"},
                // {label: "售后有效期", key: "days", required: true, tip: "自用户确认收货后开始计算", inputType: ""},
                {label: "退款免审核", key: "autoRefund", type: "switch", tip: "当确认收货后，立即自动退款给用户"},
                {label: "收货地址", key: "address", required: true, tip: "当用户通过审核后，用户将商品寄回到该地址", maxLength: 100},
                {label: "联系人", key: "receiver", required: true, maxLength: 10},
                {label: "联系方式", key: "mobile", required: true},
            ]
        }
    },
    mounted() {
        this.$post("/shop/api/refund/getConfig").then(data => {
            this.data = data;
        })
    },
    methods: {
        save() {
            let flag = false;
            this.items.some(it => {
                if (it.required && !this.data[it.key]) {
                    flag = true;
                    this.$message.error("请填写" + it.label)
                    return true;
                }
            })
            if (flag) return;
            let reg = /^(([0-9]{3,4}-)?[0-9]{7,8}|(1[34578]\d{9}))$/;
            if (!reg.test(this.data.mobile)) {
                this.$message.error("请填写正确的联系方式")
                return;
            }
            this.$post("/shop/api/refund/addOrEditConfig", this.data).then(data => {
                this.$message({
                    message: (this.data.refundConfigId ? "编辑" : "修改") + "成功",
                    type: 'success'
                });
            })
        },
    }
}
</script>

<style lang="scss">
.page.after-market-config {
    padding: 40px;
    .cell {
        display: flex;
        align-items: center;
        height: 60px;
        .label {
            width: 100px;
            text-align: right;
            span {
                color: red;
                margin-right: 5px;
            }
        }
        .value-content {
            margin: 0 20px;
            .switch-tip {
                font-weight: 600;
                margin-left: 10px;
                vertical-align: middle;
            }
            .el-input__inner {
                height: 36px;
                line-height: 36px;
            }
        }
    }
    .btn {
        cursor: pointer;
        width: 120px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin: 0 auto;
        color: #fff;
        background-color: rgb(217,83,79);
        border-radius: 6px;
    }
}
</style>